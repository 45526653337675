.lead {
    font-weight: 400 !important;
}

.low-opacity-shape:nth-of-type(2) {
    opacity: 0.2 !important;
}

.shape-lock {
    font-size: 1px;
    position: relative;
    top: 10em;
    width: 18em;
    height: 13em;
    border-radius: 2em;
    box-sizing: border-box;
    border: 3.5em solid #017CB7;
    border-right-width: 7.5em;
    border-left-width: 7.5em;
    opacity: 0.5;
}
.shape-lock::before {
    content: '';
    box-sizing: border-box;
    position: absolute;
    top: -12em;
    left: 50%;
    width: 14em;
    height: 12em;
    margin-left: -7em;
    border: 2.5em solid #017CB7;
    border-top-left-radius: 7em;
    border-top-right-radius: 7em;
}
  
.shape-lock::after {
    content: "";
    box-sizing: border-box;
    position: absolute;
    top: -1em;
    left: 50%;
    width: 5em;
    height: 8em;
    border: 1em solid #017CB7;
    border-radius: 2.5em;
    margin-left: -2.5em;
}

.shape-map-marker {
    border-radius: 50%;
    border: 6px solid #017CB7;
    width: 24px;
    height: 24px;
    opacity: 0.4;
  }
  
  .shape-map-marker::after {
    position: absolute;
    content: "";
    width: 0;
    height: 0;
    bottom: -30px;
    left: -4px;
    border: 10px solid transparent;
    border-top: 19px solid #017CB7;
  }

.alter2-header .animation-shape.shape-key {
    top: 35%;
    left: 15%;
    -webkit-animation-duration: 18s;
    animation-duration: 18s;
    color: #FF5E6B;
}

.alter2-header .animation-shape.shape-lock {
    top: 50%;
    left: 50%;
    -webkit-animation-duration: 12s;
    animation-duration: 12s;
}

.alter2-header .animation-shape.shape-map-marker {
    -webkit-animation-duration: 20s;
    animation-duration: 20s;
}

